import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import brand from '../../../assets/images/logo-icon-blk.png';
// import ArrowIcon from '../../../assets/images/right-arrow.png';
import BoostedIconRight from '../../../assets/images/next-btn-right-elect.png';
import BoostedIconLeft from '../../../assets/images/next-btn-left-elect.png';
// import ProfileIcon from '../../../assets/images/profile-img-standard.png';
// import EditIcon from '../../../assets/images/edit-icon.png';
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import { deleteSeekerProfile as deleteSeekerProfileApi, 
  deletePosterProfile as deletePosterProfileApi } from "../../../api/users";
import Alert from "sweetalert2";
import { fetchjobposter as fetchjobposterApi, 
  updateAssJobData as updateAssJobDataApi } from '../../../api/jobs-data';
import { getJobRolesByCat as getJobRolesByCatApi } from '../../../api/job-roles';
import { GetProfileData as GetProfileDataApi } from '../../../api/users';
import LocationSearchInput from '../locationAutoSuggestion/autosugestion';
import {
  API_ADD_DOCUMNET_JOBSEEKER,
  API_ADD_IMAGE_DOCUMNET_JOBSEEKER,
  API_CURRENT_USER,
  API_GET_SEEKER_DETAIL,
  API_PERSONAL_INFO,
  API_SUBMIT_JOBSEEKER_DATA,
  API_UPDATE_ACTIVE_STATUS,
  API_UPDATE_ACTIVE_STATUS_FOR_JOB,
  API_SET_POINTS,
  postApi,
  postApiPoster,
  // SEEKER_DELETE_JOB,
} from '../../../utils';
import HeaderMenu from '../../../components/HeaderMenu/headerMenu';
// import JobRoleUpdate from './updateModal/JobRoleUpdate';
// import JobTitleUpdate from './updateModal/JobTitleUpdate';
// import IndustryUpdate from './updateModal/IndustryUpdate';
// import EducationUpdate from './updateModal/EducationUpdate';
// import SkillLevelUpdate from './updateModal/SkillLevelUpdate';
// import LocationUpdate from './updateModal/LocationUpdate';
// import CompanySizeUpdate from './updateModal/CompanySizeUpdate';
// import MinPayUpdate from './updateModal/MinPayUpdate';
import HardSkillUpdate1 from './updateModal/HardSkillUpdate1';
import SoftSkillUpdate1 from './updateModal/SoftSkillUpdate1';
// import VideoUpdate from './updateModal/VideoUpdate';
// import EmploymentTypeUpdateModal from './updateModal/EmploymentTypeUpdate';

import { useDispatch } from "react-redux/es/exports";
import { handelUserLogout } from '../../../redux/actions';
import { downloadUserCsv as downloadUserCsvApi } from '../../../api/users';

function Profile() {

const { state } = useLocation();
const navigation = useNavigate();
const dispatch = useDispatch();

const baseCsvDownloadCsvText = 'Download CV'
const baseUpdateBtn = 'Update'
const companySizes = ['1-20 employee', '21-50 employee', '51-100 employee', '101-250 employee', '251+ employee']
const employmentTypes = ['Permanent', 'Contract', 'Freelance']
const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
const userData = useSelector((state) => state.UserAuth.userData.payload.user);
const userType = useSelector((state) => state.userType.userType);
const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);
const [isLoading, setLoading] = useState(true);
const [jobData, setJobData] = useState(null)
const [updateBtnText, setUpdateBtnText] = useState(baseUpdateBtn)
const [jobRoles, setJobRoles] = useState([])
const [skillLevels, setSkillLevels] = useState([])
const [educationLevels, setEducationLevels] = useState([])
const [jobIndustries, setJobIndustries] = useState([])
const [csvDownloadCsvText, setCsvDownloadCsvText] = useState(baseCsvDownloadCsvText)
const [userProfile, setUserProfile] = useState(null)
const [showHardSkillModal, setShowHardSkillModal] = useState(false)
const [showSoftSkillModal, setShowSoftSkillModal] = useState(false)
const [selectedEmploymentMode, setSelectedEmploymentMode] = useState('')
const [selectedEmploymentModeValue, setSelectedEmploymentModeValue] = useState('')

//******Watch the current Index changes

useEffect(() => {
  loadUserAssJobInfo()
  getJobRoles()
  getJobSkillLevels()
  getEduLevels()
  getIndustries()
  getProfileData()
}, [])

function loadUserAssJobInfo(){
  fetchjobposterApi(userId, 1).then((_resp) => {
    const record = _resp.data.result[0]
    console.log('job data - ', record)
    setJobData(record)
    setSelectedEmploymentModes(record)
  }).catch((_resp) => {

  }).finally(() => {
    setLoading(false)
  })
}

function setSelectedEmploymentModes(_record){
  const data = _record['employmentMode']
  console.log(data.split(':'))
  setSelectedEmploymentMode(data.split(':')[0])
  setSelectedEmploymentModeValue(data.split(':')[1])
  console.log()
}

function getJobRoles(){
  getJobRolesByCatApi('jobrole', 1, 'front-end').then((_resp) => {
    let data = _resp.data.result
    const options = data.map(x => x.option.toLowerCase())
    setJobRoles(options)
  }).catch((_resp) => {
    console.error(_resp)
  }).finally(() => {

  })
}

function getJobSkillLevels(){
  getJobRolesByCatApi('skilllevel', 1, 'front-end').then((_resp) => {
    let data = _resp.data.result
    const options = data.map(x => x.option.toLowerCase())
    setSkillLevels(options)
  }).catch((_resp) => {
    console.error(_resp)
  }).finally(() => {

  })
}

function getEduLevels(){
  getJobRolesByCatApi('educationlevel', 1, 'front-end').then((_resp) => {
    let data = _resp.data.result
    const options = data.map(x => x.option.toLowerCase())
    setEducationLevels(options)
  }).catch((_resp) => {
    console.error(_resp)
  }).finally(() => {

  })
}

function getIndustries(){
  getJobRolesByCatApi('jobindustry', 1, 'front-end').then((_resp) => {
    let data = _resp.data.result
    const options = data.map(x => x.option.toLowerCase())
    setJobIndustries(options)
  }).catch((_resp) => {
    console.error(_resp)
  }).finally(() => {

  })
}

function getProfileData(){
  GetProfileDataApi(userId, 1).then((_resp) => {
    setUserProfile(_resp.data.data)
  }).catch((_resp) => {

  }).finally(() => {

  })
}

//delete job
const deleteJob = async () => {
  Alert.fire({
    title: "Are you sure?",
    text: "You want to delete your account",
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      deleteProfile();
    }
  });
};

const deleteProfile = () => {
  const type = userType.payload
  if(type === 'jobSeeker'){
    deleteSeeker()
  }else{
  }
};

function deleteSeeker(){
  deleteSeekerProfileApi(userId).then((_resp) => {
    if(_resp.data.result === 'success'){
      handleLogout()
    } else {
        // console.log(_resp.data);
    }
  }).catch((_resp) => {
      console.error(_resp);
  });
}

const handleLogout = () => {
  navigation("/");
};

function onDownloadCsvClick(_link) {
  setCsvDownloadCsvText('Downloading...');
  downloadUserCsvApi(_link).then((_resp) => {
    const fileName = _link.split('/').pop();
    const fileBlob = new Blob([_resp.data]);
    

    // Create a URL for the Blob and trigger the download
    const url = window.URL.createObjectURL(fileBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }).catch((_resp) => {
      console.error(_resp);
  }).finally(() => {
      setCsvDownloadCsvText(baseCsvDownloadCsvText);
  });
}

function onUpdateClick(){
  setUpdateBtnText('Updating...')
  updateAssJobDataApi(jobData).then((_resp) => {
    if(_resp.data.Result === 'SUCCESS'){
      toast.success("Details Updated", { position: "top-right", autoClose: 1000 });
    }else{

    }
  }).catch((_resp) => {
    console.error(_resp)
  }).finally(() => {
    setUpdateBtnText(baseUpdateBtn)
  })
}

function onKeyValueChange(_key, _value){
  let tmp = {...jobData}
  tmp[_key] = _value
  setJobData(tmp)
}

function onSubKeyValueChange(_key, _sub_key, _value){
  let tmp = {...jobData}
  if(['industry', 'role'].includes(_key)){
    tmp[_key][0][_sub_key] = _value
  }else{
    tmp[_key][_sub_key] = _value
  }
  setJobData(tmp)
}

const handleLocationSelected = location => {
  // console.log(location)
  onKeyValueChange('locationName', location)
  // setLocationSelected(location);
};

function onAddHardSkillClick(){
  setShowHardSkillModal(true)
}

function onAddSoftSkillClick(){
  setShowSoftSkillModal(true)
}

function handleHardSkillUpdate(_args){
  let hard_skills = jobData.hardSkill
  hard_skills.push(_args)
  onKeyValueChange('hardSkill', hard_skills)
}

function handleSoftSkillUpdate(_args){
  let soft_skills = jobData.softSkill
  soft_skills.push(_args)
  onKeyValueChange('softSkill', soft_skills)
}

function onEmploymentModeChange(_type, _value){
  let x = ``
  if(_type === 'mode'){
    x = `${_value}:${selectedEmploymentModeValue}`
    setSelectedEmploymentMode(_value)
  }else if(_type === 'value'){
    x = `${selectedEmploymentMode}:${_value}`
    setSelectedEmploymentModeValue(_value)
  }

  onKeyValueChange('employmentMode', x)
}

function updateProfileFunc(_cmd, _value){
  onKeyValueChange(_cmd, _value)

  setTimeout(() => {
    const ele = document.getElementById('btn-update-user-data')
    ele.click()
  }, 1000)
}

return (
  <React.Fragment>
    <HeaderMenu />
    {isLoading && (
      <div className="d-flex justify-content-center align-items-center LoaderCenter">
        <div className="block">
          <span
            className="spinner-grow spinner-grow-lg mx-2"
            role="status"
            aria-hidden="true"
          />
          <h6 className="LoaderText">Processing...</h6>
        </div>
      </div>
    )}
    {!isLoading && (
      <section className='container' id="dashboard-cnt-area">
        <div className="ms-4 me-4 mb-4 d-flex justify-content-between align-items-center">
          <div className='d-flex justify-content-start'>
            <img src={userData['imagePath']} className="img img-fluid ms-2" style={{ height: '60px', width: '60px', borderRadius: '10px' }}/>
            <h4 className="mb-0 ms-3">{userData['fullName']}</h4>
          </div>
          {userData?.isPaid ? (
                <div className="form-btn btn-warning"
                    style={{ background: "#ffe923", margin: '0', fontSize: '20px', minHeight: '57px', height: 'auto', display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img src={BoostedIconLeft} style={{height: "50px"}} />Profile Boosted<img src={BoostedIconRight} style={{height: "50px"}} />
                </div>
            ) : (
                <button
                    className="form-btn videoUploadBtn btn-warning"
                    style={{ background: "#ffe923", margin: '0', fontSize: '20px', minHeight: '57px', height: 'auto' }}
                    onClick={() => navigation("../boostProfile")}
                >
                  Boost profile
                </button>
            )}
        </div>
        <div className="row m-4">
          <div className="col-lg-4 col-xs-12 mb-2">
            <div className="card">
              <div className="list-group">
                <a className="list-group-item border-0" >
                  <div className="signup-input-bx">
                    <label className="">Job Title</label>
                    <input onChange={(e) => {onSubKeyValueChange('jobTitle', 'option', e.target.value)}} value={jobData.jobTitle.option} type="text" />
                  </div>
                </a>
                <a className="list-group-item border-0" >
                  <div className="signup-input-bx">
                    <label className="">Industry</label>
                    {/* <select onChange={(e) => {onSubKeyValueChange('industry', 'option', e.target.value)}} value={jobData.industry[0].option} className='form-select'>
                      {jobIndustries.map((x, index) => 
                        <option value={x} key={index}>{x}</option>
                      )}
                    </select> */}
                    <input onChange={(e) => {onSubKeyValueChange('industry', 'option', e.target.value)}} value={jobData.industry[0].option} type="text" />
                  </div>
                </a>
                <a className="list-group-item border-0" >
                  <div className="signup-input-bx">
                    <label className="">Employment Type</label>
                    <select onChange={(e) => {onKeyValueChange('employmentType', e.target.value)}} value={jobData.employmentType} className='form-select'>
                      {employmentTypes.map((x, index) => 
                        <option value={x} key={index}>{x}</option>
                      )}
                    </select>
                    {/* <input onChange={(e) => {onKeyValueChange('employmentType', e.target.value)}} value={jobData.employmentType} type="text" /> */}
                  </div>
                </a>
                <a className="list-group-item border-0" >
                  <div className="signup-input-bx">
                    <label className="">Employment Mode</label>
                    {/* <input onChange={(e) => {onKeyValueChange('employmentMode', e.target.value)}} value={selectedEmploymentMode} type="text" /> */}
                    <select onChange={(e) => onEmploymentModeChange('mode', e.target.value)} value={selectedEmploymentMode} className='form-select'>
                      <option value="hourly-rate">Hourly</option>
                      <option value="monthly">Monthly</option>
                      <option value="annual">Annual</option>
                    </select>
                  </div>
                </a>
                <a className="list-group-item border-0" >
                  <div className="signup-input-bx">
                    <label className="">Employment Mode Value</label>
                    <select onChange={(e) => onEmploymentModeChange('value', e.target.value)} value={selectedEmploymentModeValue} className='form-select'>
                      <option value="£1000">£1000</option>
                      <option value="£1500">£1500</option>
                      <option value="£2000">£2000</option>
                      <option value="£2500">£2500</option>
                      <option value="£3000">£3000</option>
                      <option value="£3500">£3500</option>
                      <option value="£4000">£4000</option>
                    </select>
                    {/* <input onChange={(e) => {onKeyValueChange('employmentMode', e.target.value)}} value={selectedEmploymentMode} type="text" /> */}
                  </div>
                </a>
                <a className="list-group-item border-0" >
                  <div className="signup-input-bx">
                    <label className="">Culture</label>
                    <select onChange={(e) => {onSubKeyValueChange('role', 'option', e.target.value)}} value={jobData.role[0].option} className='form-control'>
                      {jobRoles.map(x => 
                        <option key={x} value={x}>{x}</option>
                      )}
                    </select>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xs-12">
            <div className="card border-0">
              <div className="list-group">
                <a className="list-group-item border-0" >
                  <div className="signup-input-bx">
                    <label className="">Location</label>
                    {/* <input onChange={(e) => {onKeyValueChange('locationName', e.target.value)}} value={jobData.locationName} type="text" /> */}
                    <LocationSearchInput address={jobData.locationName} onLocationSelected={handleLocationSelected} />
                  </div>
                </a>
                <a className="list-group-item border-0" >
                  <div className="signup-input-bx">
                    <label className="">Remote Work</label>
                    <select onChange={(e) => {onKeyValueChange('remotely',  Boolean(e.target.value))}} value={jobData.remotely} className='form-select'>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </select>
                  </div>
                </a>
                <a className="list-group-item border-0" >
                  <div className="signup-input-bx">
                    <label className="">Company Size</label>
                    <select onChange={(e) => {onKeyValueChange('campanySize', e.target.value)}} value={jobData.campanySize} className='form-select'>
                        {companySizes.map((x, index) => 
                          <option value={x} key={index}>{x}</option>
                        )}
                    </select>
                  </div>
                </a>
                <a className="list-group-item border-0" >
                  <div className="signup-input-bx">
                    <label className="">Education Level</label>
                    <select onChange={(e) => {onSubKeyValueChange('educationLevel', 'option', e.target.value)}} value={jobData.educationLevel.option} className='form-select'>
                      {educationLevels.map((x, index) => 
                        <option value={x} key={index}>{x}</option>
                      )}
                    </select>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xs-12 mb-2">
            <div className="card border-0">
              <div className="list-group">
                <a className="list-group-item border-0" >
                  <div className="signup-input-bx">
                    <label className="">Skill Level</label>
                    <select onChange={(e) => {onSubKeyValueChange('skillLevel', 'option', e.target.value)}} value={jobData.skillLevel.option} className='form-select'>
                      {skillLevels.map((x, index) => 
                        <option value={x} key={index}>{x}</option>
                      )}
                    </select>
                    {/* <input onChange={(e) => {onSubKeyValueChange('skillLevel', 'option', e.target.value)}} value={jobData.skillLevel.option} type="text" /> */}
                  </div>
                </a>
                <a className="list-group-item border-0" >
                  <div className="d-flex w-100 justify-content-between">
                    <span className="mb-1">Soft Skill</span>
                    <i onClick={onAddSoftSkillClick} className='fa fa-edit' type={'button'}></i>
                  </div>
                  <ul className='text-start'>
                    {jobData.softSkill.map((sk, index) => 
                      <li key={index} className='text-start'>{sk.skill.option}</li>
                    )}
                  </ul>
                </a>
                <a className="list-group-item border-0" >
                  <div className="d-flex w-100 justify-content-between">
                    <span className="mb-1">Hard Skill</span>
                    <i onClick={onAddHardSkillClick} className='fa fa-edit' type={'button'}></i>
                  </div>
                  <ul className='text-start'>
                    {jobData.hardSkill.map((sk, index) => 
                      <li key={index} className='text-start'>{sk.skill.option}</li>
                    )}
                  </ul>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className='d-flex justify-content-between'>
          <div className='d-flex justify-content-start'>
            {userProfile !== null?
              <button onClick={() => onDownloadCsvClick(userProfile.documentPath)} className="form-btn" style={{margin: '0', fontSize: '20px', minHeight: '57px', height: 'auto'}} disabled={csvDownloadCsvText !== baseCsvDownloadCsvText}>{csvDownloadCsvText}</button>
            : null}
            <button onClick={() => { deleteJob();}} className="form-btn btn-warning" style={{ background: "#ff0e0e", margin: '0', fontSize: '20px', minHeight: '57px', height: 'auto' }}>
              Delete Account
            </button>
          </div>
          <button onClick={onUpdateClick} disabled={updateBtnText !== baseUpdateBtn} id='btn-update-user-data' className='form-btn float-end'>{updateBtnText}</button>
        </div>
      </section>
    )}
    {jobData !== null?
      <HardSkillUpdate1
        prevHardSkill={jobData.hardSkill}
        hard1Modal={showHardSkillModal}
        setHard1Modal={setShowHardSkillModal}
        updateProfileFunc={updateProfileFunc}
        onAddSkill={handleHardSkillUpdate}
      />
    : null}
    {jobData !== null?
      <SoftSkillUpdate1
        prevSoftSkill={jobData.softSkill}
        soft1Modal={showSoftSkillModal}
        setSoft1Modal={setShowSoftSkillModal}
        updateProfileFunc={updateProfileFunc}
        onAddSkill={handleSoftSkillUpdate}
      />
    : null}
  </React.Fragment>
);
}

export default Profile;
